import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout'
import { SectionWrapper } from "../components/composites";
import { SignUpForm } from "../components/forms";


import './sign-up.scss'

export const SignUpPage = ({location}) => {


    return(
        <Layout
            className='sign-up-page'
            currentPage='sign-up'
            title='Sign Up | VIND '
            location={location}
            description='Are you a winery? Sign up to join VIND'
        >

            <SectionWrapper small>
                <div className="section-content">
                    <div className="section-content-entry">
                        <div className="section-content-text">
                            <h6 className="section-title">Sign Up</h6>
                            <p>VIND is free to join, please fill this form to create an account. Once you submit the form, we will send an email with instructions to setup your password and add your winery.</p>
                        </div>
                    </div>
                </div>

                <div className="section-body">
                    <div className="shell-small">
                        <SignUpForm/>
                        <p><small>By completing this form, I agree to the Terms and Privacy Policy. I want to receive promotional emails, unless I opt out.</small></p>
                    </div>
                </div>
            </SectionWrapper>


            {/*<button onClick={() => submitSignUp()}>Test User Create</button>*/}
            {/*<button onClick={() => submitWineryCreate()}>Test Winery Create</button>*/}

            {/*<div className="pb-0">*/}
            {/*    <form id="signin" method="post" onSubmit={this.handleSignInSubmit}>*/}
            {/*        <input name="_csrf" type="hidden" value={this.state.session.csrfToken} />*/}
            {/*        <p>*/}
            {/*            <label htmlFor="email">Email address</label><br />*/}
            {/*            <input name="email" type="text" placeholder="j.smith@example.com" id="email"*/}
            {/*                   className="form-control" value={this.state.email} onChange={this.handleEmailChange} />*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            <label htmlFor="password">Password</label><br />*/}
            {/*            <input name="password" type="password" placeholder="" id="password" className="form-control"*/}
            {/*                   value={this.state.password} onChange={this.handlePasswordChange} />*/}
            {/*        </p>*/}
            {/*        <p className="d-flex justify-content-between align-items-center flex-wrap">*/}
            {/*            <button id="submitButton" type="submit" className={`btn pink ${styles.btn}`}>Sign in</button>*/}
            {/*            <br />*/}
            {/*            <Link href="/auth/forgotpassword"><a className={styles.btnPink}>Forgot password?</a></Link>*/}
            {/*        </p>*/}
            {/*    </form>*/}
            {/*</div>*/}
            {/*<p>test</p>*/}
        </Layout>
    )
}

SignUpPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}

SignUpPage.defaultProps = {
}

export default SignUpPage